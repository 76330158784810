import React, { Component } from 'react';

import border from '../../images/border.png';
import twitter from '../../images/twitter.png';
import student from '../../images/englishStudent.webp';
import {Helmet } from 'react-helmet';

import {Button} from 'antd';

import { withRouter } from 'react-router-dom';
import Liens from './Liens.js';



 class Presentation extends Component {

  constructor()
  {
    super();
   

  }


  reset = () => {
    localStorage.clear();
    document.location.reload();
}
  render() {
    return <div className="margeHaut">
        <Helmet>
          <title>Tests d'anglais de différents niveaux gratuits et en ligne avec correction</title>
          <meta name="description" content="Des tests faciles avec correction, gratuits et en ligne pour évaluer son niveau d'anglais. Différents types de tests sont disponibles, exercices de conjugaison, tests de vocabulaire, de compréhension orale..."/>
                 </Helmet>
              
      <h1 className="titre centre">Apprendre l'anglais gratuitement</h1>
      <div className='centre'><small >evalquiz.com : numéro 1 du divertissement intelligent</small></div>
     <div className="centre"><img src={border} alt="bordure"></img></div>
   <div className="texteBord">
    <p>Ce site vous propose différents tests et exercices pour s'entraîner en anglais.</p>
    <p>Les exercices concernent tous les aspects de la langue anglaise : grammaire, vocabulaire, compréhension orale et écrite.
    Tous les tests en ligne sont gratuits et ne nécessitent aucune inscription. Vous pouvez réaliser les tests dans n'importe quel ordre. Les tests sont de différents niveaux mais ils se concentrent surtout sur le niveau B1.
    La plupart des exercices concernent un anglais général. La correction est disponible pour tous les exercices.
  Pour pallier à la difficulté des multiples traductions (you peut se traduire vous ou tu, multiples traductions du mot nice) des aides sont disséminées dans les exercices.</p><p> Ce site n'est pas une méthode pour apprendre l'anglais, il a été principalement conçu pour les personnes qui veulent (re)travailler leur anglais tout en se divertissant.</p>
   </div>




<div className="centre"><img src={border} alt="bordure"></img></div>
<div className="centre"><img className="img-responsive" src={student} alt="student" width={1000} height={402}></img></div>
<Liens></Liens>
<div className="texteBord">
<p className="margeHaut20">Si vous souhaitez supprimer les indications 'déja fait' et 'à refaire' vous pouvez cliquer sur le bouton Reset. Attention ! Toutes vos données seront perdus.</p>
<div><Button type="primary" onClick={this.reset}>Reset</Button></div>

<p>Vous avez des exercices à proposer ou des idées d'amélioration de notre site ? Vous pouvez directement prendre contact avec nous sur <a href="https://twitter.com/evalquiz">Twitter <img className="margeGauche" src={twitter} alt="twitter logo"></img> </a>. Nous sommes toujours heureux d'avoir des retours.</p>
<p>Vous pouvez aussi nous contacter si vous avez des remarques ou des propositions en consultant la page des <a href="https://evalquiz.com/home/faq">mentions légales.</a></p>


<div className="centre"><img src={border} alt="bordure"></img></div>
</div>
    </div> 
  }
}

export default withRouter(Presentation);