import React, { Component, Suspense } from 'react';
import { Switch, Route} from 'react-router-dom'

import Presentation from './Presentation'
import Exercice from './Exercice';
import Niveaux from './Niveaux';
import Tableau from '../../jeux/commun/Tableau';
import GrammaireTest from './Grammaire';
import EcouteTest from './Ecoute';
import ParlerTest from './Parler';
import Verbes from './Verbes';
import env from '../commun/config';
import Alphabet from './Alphabet';
import { BrowserRouter } from 'react-router-dom';
import Scroll from './Top';
import PresEvaluation  from './Evaluation';


const Evaluation = React.lazy(() => import('../../jeux/eval/JeuEval'));
const VocabulaireTest = React.lazy(() => import('./Vocabulaire'));
const VocabulaireTestAnglais = React.lazy(() => import('./VocabulaireAnglais'));
const JeuOral = React.lazy(() => import('../../jeux/oral/JeuOral'));
const JeuVoca500 = React.lazy(() => import('../../jeux/voca500/JeuVoca500'));
const JeuCode = React.lazy(() => import('../../jeux/code/JeuCode'));
const JeuEcoute = React.lazy(() => import('../../jeux/ecoute/JeuEcoute'));
const JeuVerbe = React.lazy(() => import('../../jeux/conjugaison/JeuVerbe'));
const JeuListeMots = React.lazy(() => import('../../jeux/listeMots/ListeMots'));
const JeuMemoire = React.lazy(() => import('../../jeux/memoire/JeuMemoire'));
const JeuRapide = React.lazy(() => import('../../jeux/rapidite/JeuRapide'));
const JeuMotParle = React.lazy(() => import( '../../jeux/motParle/JeuMotParle'));
const JeuQcm = React.lazy(() => import('../../jeux/qcm/JeuQcm'));
const DebutEtape = React.lazy(() => import('../../jeux/concours/DebutEtape'));
const JeuVerbeIrregulier = React.lazy(() => import('../../jeux/verbe/JeuVerbeIrregulier'));
const JeuPhraseTrou = React.lazy(() => import('../../jeux/trou/PhraseTrou'));
const TestNiveaux = React.lazy(() => import('../../jeux/niveaux/qcm/JeuQcm'));
const TestNiveauxTexte = React.lazy(() => import('../../jeux/niveaux/qcm/JeuQcmTexte'));
const Vocabulaire = React.lazy(() => import('../../pages/Vocabulaire'));
const Vocabulaire2 = React.lazy(() => import('../../pages/Vocabulaire2'));
const Phrase = React.lazy(() => import('../../pages/Phrase'));
const Phrase2 = React.lazy(() => import('../../pages/Phrase2'));
const Phrases = React.lazy(() => import('./Phrases'));
const PhrasesEnglish = React.lazy(() => import('./PhrasesAnglais'));

export default class Routeur extends Component {

    render() {
        return (
            <BrowserRouter >  
            <Scroll></Scroll>   
            <Switch >
            <Suspense fallback={<div>Chargement de la page...</div>}>
            <Route exact path='/' component={Presentation} />
                <Route path='/menu' component={Presentation} />
                <Route path={'/' + env.nomVocabulaire + '/:type/:langue/:id'} render={(props) => <JeuVoca500 {...props} keyProp={'jeuxVocabulaire'} key={Math.floor(Math.random() * 10000)} />} />
                <Route path={'/' + env.nomEcoute +'/:id'} render={(props) => <JeuEcoute {...props} keyProp={'jeuEcoute'} key={Math.floor(Math.random() * 10000)} />} />
                <Route path={'/' + env.nomConjugaison +'/:id'} render={(props) => <JeuVerbe {...props} keyProp={'jeuConjugaison'} key={Math.floor(Math.random() * 10000)} />} />            
                <Route path={'/' + env.nomOral + '/:id'} render={(props) => <JeuOral {...props} keyProp={'jeuOral'} key={Math.floor(Math.random() * 10000)} />} />            
                <Route path={'/' + env.nomListe +'/:id'} render={(props) => <JeuListeMots {...props} keyProp={'jeuListeMots'} key={Math.floor(Math.random() * 10000)} />} />            
                <Route path={'/' + env.nomMemoire +'/:id'} render={(props) => <JeuMemoire {...props} keyProp={'jeuListeMots'} key={Math.floor(Math.random() * 10000)} />} />            
                <Route path={'/' + env.nomRapide + '/:id'} render={(props) => <JeuRapide {...props} keyProp={'jeuRapide'} key={Math.floor(Math.random() * 10000)} />} />            
                <Route path={'/' + env.nomMotParle + '/:id'} render={(props) => <JeuMotParle {...props} keyProp={'jeuMotParle'} key={Math.floor(Math.random() * 10000)} />} />   
                <Route path={'/' + env.nomQcm + '/:id'} render={(props) => <JeuQcm {...props} keyProp={'jeuQcm'} key={Math.floor(Math.random() * 10000)} />} /> 
                <Route path={'/' + env.nomPhraseTrou + '/:id'} render={(props) => <JeuPhraseTrou {...props} keyProp={'jeuPhraseTrou'} key={Math.floor(Math.random() * 10000)} />} />                                         
                <Route path={'/' + env.verbesIrreguliers} render={(props) => <JeuVerbeIrregulier {...props} keyProp={'jeuVerbe'} key={Math.floor(Math.random() * 10000)} />} />                                            
                <Route path={'/jeu-code'} render={(props) => <JeuCode {...props} keyProp={'jeuCode'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/alphabet'} render={(props) => <Alphabet {...props} keyProp={'alphabet'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/exercice'} render={(props) => <Exercice {...props} keyProp={'exercice'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/evaluation'} render={(props) => <PresEvaluation {...props} keyProp={'presevaluation'} key={Math.floor(Math.random() * 10000)} />} />                     

                <Route path={'/test-evaluation/:id'} render={(props) => <Evaluation {...props} keyProp={'evaluation'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/verbes'} render={(props) => <Verbes {...props} keyProp={'verbe'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/niveaux'} render={(props) => <Niveaux {...props} keyProp={'verbe'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-niveau/:id'} render={(props) =>  <TestNiveaux {...props} keyProp={'test-niveaux'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-niveau-texte/:id'} render={(props) =>  <TestNiveauxTexte {...props} keyProp={'test-niveaux-texte'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-phrases'} render={(props) => <Phrases {...props} keyProp={'phrases'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-phrases-english'} render={(props) => <PhrasesEnglish {...props} keyProp={'phrasesEnglish'} key={Math.floor(Math.random() * 10000)} />} />                     

                <Route path={'/test-grammaire'} render={(props) => <GrammaireTest {...props} keyProp={'grammaireTest'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-vocabulaire-english'} render={(props) => <VocabulaireTestAnglais {...props} keyProp={'vocabulaireTestAnglais'} key={Math.floor(Math.random() * 10000)} />} />    

                <Route path={'/test-vocabulaire'} render={(props) => <VocabulaireTest {...props} keyProp={'vocabulaireTest'} key={Math.floor(Math.random() * 10000)} />} />    
                <Route path={'/test-ecoute'} render={(props) => <EcouteTest {...props} keyProp={'ecouteTest'} key={Math.floor(Math.random() * 10000)} />} />                     
                <Route path={'/test-parler'} render={(props) => <ParlerTest {...props} keyProp={'parlerTest'} key={Math.floor(Math.random() * 10000)} />} />                                      
                <Route path='/defi-anglais/:no' render={(props) => <DebutEtape {...props} keyProp={'debutEtape'} key={Math.floor(Math.random() * 10000)} />} />
                <Route path='/tableau/:type' render={(props) => <Tableau {...props} keyProp={'tableau'} key={Math.floor(Math.random()*10000)}/>}/>
                <Route path={'/vocabulaire-anglais'} component={Vocabulaire} />   
                <Route path={'/vocabulaire-francais'} component={Vocabulaire2} /> 
                <Route path={'/phrase-anglais'} component={Phrase2} />   
                <Route path={'/phrase-francais'} component={Phrase} />   
                </Suspense>          
           </Switch>  
           </BrowserRouter>
        );
    }
}